import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/zhannum/git/the-dark-between/src/layouts/DefaultLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The `}<strong parentName="p">{`Noble Houses of `}<a parentName="strong" {...{
          "href": "/Aljieudum",
          "title": "Aljieudum"
        }}>{`Aljieudum`}</a>{``}</strong>{` rule over the various administrative districts as `}<a parentName="p" {...{
        "href": "/Viceroy",
        "title": "Viceroy"
      }}>{`Viceroys`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      